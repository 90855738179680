import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VDataTable,{staticClass:"table-rounded",attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.userListData,"show-select":"","item-key":"fullName","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c(VImg,{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.fullName.slice(0,2).toUpperCase()))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary  font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.fullName))]),_c('small',[_vm._v(_vm._s(item.userName))])])],1)]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:("v-avatar-light-bg " + (_vm.role[item.role].color) + "--text me-3"),attrs:{"size":"30","color":_vm.role[item.role].color}},[_c(VIcon,{attrs:{"size":"20","color":_vm.role[item.role].color}},[_vm._v(" "+_vm._s(_vm.role[item.role].icon)+" ")])],1),_c('span',[_vm._v(_vm._s(item.role))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.statusColor[item.status]) + "--text"),attrs:{"small":"","color":_vm.statusColor[item.status]}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }