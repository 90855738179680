import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c('div',{staticClass:"d-flex flex-sm-row flex-column"},[_c('div',{staticClass:"flex-grow-1"},[_c(VCardTitle,[_c('span',{staticClass:"me-3"},[_vm._v("Deposit")]),_c(VSpacer),_c('span',{staticClass:"text-xs text--disabled cursor-pointer"},[_vm._v("View All")])],1),_c(VCardText,[_c(VList,_vm._l((_vm.deposit),function(data,index){return _c(VListItem,{key:data.img,class:("d-flex px-0 " + (index > 0 ? 'mt-4':''))},[_c(VImg,{staticClass:"me-3",attrs:{"contain":"","max-height":"30","max-width":"30","src":data.img}}),_c('div',{staticClass:"d-flex align-center flex-grow-1 flex-wrap"},[_c('div',{staticClass:"me-auto pe-2"},[_c('h4',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(data.subtitle))])]),_c('span',{staticClass:"font-weight-semibold success--text"},[_vm._v(_vm._s(data.amount))])])],1)}),1)],1)],1),_c(VDivider,{staticClass:"my-sm-5 mx-5",attrs:{"vertical":_vm.$vuetify.breakpoint.smAndUp}}),_c('div',{staticClass:"flex-grow-1"},[_c(VCardTitle,[_c('span',{staticClass:"me-3"},[_vm._v("Withdraw")]),_c(VSpacer),_c('span',{staticClass:"text-xs text--disabled cursor-pointer"},[_vm._v("View All")])],1),_c(VCardText,[_c(VList,_vm._l((_vm.withdraw),function(data,index){return _c(VListItem,{key:data.img,class:("d-flex px-0 " + (index > 0 ? 'mt-4':''))},[_c(VImg,{staticClass:"me-3",attrs:{"max-height":"30","max-width":"30","src":data.img}}),_c('div',{staticClass:"d-flex align-center flex-grow-1 flex-wrap"},[_c('div',{staticClass:"me-auto pe-2"},[_c('h4',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(data.subtitle))])]),_c('span',{staticClass:"font-weight-semibold error--text"},[_vm._v(_vm._s(data.amount))])])],1)}),1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }